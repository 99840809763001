import useMediaQuery from "@mui/material/useMediaQuery"
import Layout from "components/layout"
import { Typography } from "components/typography"
import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import * as styles from "./style.module.scss"

const Profile = props => {
  const imageName = "/images/profiles/" + props.profile.photo + ".png"

  return (
    <div className={styles.root}>
      <div className={styles.photoBackground}>
        <div className={styles.photoDecoration} />
        <LazyLoadImage className={styles.photo} src={imageName} alt="" />
      </div>
      <Typography.H6 comp="title-name">{props.profile.name}</Typography.H6>
      <Typography.H6 comp="title-job">{props.profile.role}</Typography.H6>
      <Typography.P>{props.profile.info}</Typography.P>
    </div>
  )
}

function About(props) {
  const simon = {
    photo: "simon",
    name: "Simon Dillon",
    role: "Chief Operating Officer",
    info: "Simon has over 16 years of experience in the Australian and UK Home Lending, Payments and Credit Cards markets. He has been responsible for innovations such as Australia’s first Apple Pay credit card roll-out, the introduction of contactless payments, and the launch of premium rewards credit cards. He is also the founder of an online mortgage broking business.",
  }
  const anthony = {
    photo: "anthony",
    name: "Anthony Fitzgerald",
    role: "Chief Executive Officer",
    info: "Anthony has over 20 years of experience in banking, and has led Cards and Deposits businesses at both major and digital banks. He is responsible for many of the innovations that have shaped these markets, including Low Rate credit cards, 0% Balance Transfers, Visa Debit cards, and various Reward Program innovations.",
  }
  const paul = {
    photo: "paul",
    name: "Paul Kearney",
    role: "Data Science Advisor",
    info: "Paul has worked in banking, digital and analytics for over 16 years, including consumer finance, payments, and home lending. He has led bank-wide data and analytics functions through transformational change, building a deep understanding of both the opportunities and realities of the world of data.",
  }
  const nir = {
    photo: "nir",
    name: "Nir Palombo",
    role: "Chief Technology Officer",
    info: "Nir has over a decade of software engineering experience, working primarily with start-ups, including co-founding a fintech that utilises mobile device audio for secure payment processing. Nir is a hands-on leader with diverse technical skills, including mobile, desktop, web and backend development, information security and network design.",
  }

  const isWide = useMediaQuery("(min-width:768px)")

  return (
    <Layout title="Learn about our purpose and team | Stay or Go" description="With Stay or Go, you can relax. We empower you to secure the best credit card deal without having to continually switch banks." image="/images/Logo_SOG_Colour.png">
      <div className="page-container">
        <div className={styles.container}>
          <Typography.H1>About us</Typography.H1>
          <Typography.P comp="bottom-margin">With Stay or Go, you no longer need to switch banks for a better deal.</Typography.P>
          <Typography.P comp="bottom-margin">We work to get you a better deal with your current bank, and compare it to the most relevant offers from across the market. You can then choose to Stay or Go, and relax knowing that we will continue to monitor your new deal to ensure it remains competitive.</Typography.P>
          <Typography.P comp="bottom-margin">
            Our purpose at Stay or Go is simple:
            <span style={{ fontFamily: "arboria-bold", color: "#202020" }}>&nbsp;to empower loyal customers.</span>
          </Typography.P>
          <Typography.P comp="bottom-margin">Our first mission is to help you with your credit card. Later, we want to help you with your entire banking relationship, including home loans and savings.</Typography.P>
          <Typography.H3>Our leadership team</Typography.H3>
          <Typography.P>With deep experience in both banking and technology, the Stay or Go team have led the introduction of many market innovations, including contactless payments, 0% balance transfers, and market leading low rate and reward cards. We understand the market intimately, and are focused on bringing you the simplest way to get a better deal with the right bank.</Typography.P>
          {!isWide && (
            <div className={styles.profilesContainer}>
              <Profile profile={anthony} />
              <Profile profile={nir} />
              <Profile profile={simon} />
              {/* <Profile profile={paul} /> */}
            </div>
          )}
          {isWide && (
            <div className={styles.profilesContainer}>
              <div className={styles.profilesRow}>
                <Profile profile={anthony} />
                <Profile profile={nir} />
              </div>
              <div className={styles.profilesRow}>
                <Profile profile={simon} />
                {/* <Profile profile={paul} /> */}
              </div>
            </div>
          )}
          <Typography.H3>Join our team</Typography.H3>
          <Typography.P>
            Our plans are big and we are moving fast. If you love what we are doing and would like to become part of our story, we’d love to hear from you. Contact us at {/* https://github.com/ReactTraining/react-router/issues/394 */}
            <a href="mailto:careers@stayorgo.com.au" style={{ color: "#4f8ccc", textDecoration: "inherit" }}>
              careers@stayorgo.com.au
            </a>
          </Typography.P>
        </div>
      </div>
    </Layout>
  )
}

export default About
